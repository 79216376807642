import React, { useEffect, useState } from 'react'
import socialCardIcon from '../../assets/images/socialCardIcon.png'
import chatCardIcon from '../../assets/images/chatCardIcon.png'
import voiceCardIcon from '../../assets/images/voiceCardIcon.png'
import { Img } from '../../reuseableComponents/globalStyles'
import { CSSTransition } from 'react-transition-group';
import { ProductsCardContainer, ProductsCardZappyChat, ProductsCardZappyChatHeading, ProductsCardZappyChatTitle } from './productsStyles'

const ProductsCard = ({activeProductCard, showProductsContent}) => {
  const [showProductsCardOneTransition, setShowProductsCardOneTransition] = useState(false);
  const [showProductsCardTwoTransition, setShowProductsCardTwoTransition] = useState(false);
  const [showProductsCardThreeTransition, setShowProductsCardThreeTransition] = useState(false);

  useEffect(() => {
    if (showProductsContent===true && activeProductCard === '1'){
      setShowProductsCardOneTransition(true);
    }
    else{
      setShowProductsCardOneTransition(false)
    }
    if (activeProductCard === '2'){
      setShowProductsCardTwoTransition(true);
    }
    else{
      setShowProductsCardTwoTransition(false)
    }
    if (activeProductCard === '3'){
      setShowProductsCardThreeTransition(true);
    }
    else{
      setShowProductsCardThreeTransition(false)
    }
  }, [activeProductCard, showProductsContent]);
  return (
    <>
      <ProductsCardContainer active={activeProductCard === '1'}>
        <CSSTransition in={showProductsCardOneTransition} timeout={500} classNames="fade">
          <ProductsCardZappyChat show={showProductsContent}><Img src={socialCardIcon} alt="socialCardIcon" /></ProductsCardZappyChat>
        </CSSTransition>
        <CSSTransition in={showProductsCardOneTransition} timeout={300} classNames="fadeFour">
          <ProductsCardZappyChatTitle show={showProductsContent}>Levrg Social</ProductsCardZappyChatTitle>
        </CSSTransition>
        <CSSTransition in={showProductsCardOneTransition} timeout={300} classNames="fadeFour">
          <ProductsCardZappyChatHeading show={showProductsContent}>
            This is a campaign launch and lead generation AI developed by Levrg. It automates the creation of viral marketing campaigns and the generation of leads. This tool is designed to enhance the efficiency of marketing efforts, making it easier for businesses to reach their target audience and generate interest in their products or services. It's a part of Levrg's suite of tools aimed at improving the operational efficiency of online businesses by leveraging AI technology.
          </ProductsCardZappyChatHeading>
        </CSSTransition>
      </ProductsCardContainer>
      <ProductsCardContainer active={activeProductCard === '2'}>
        <CSSTransition in={showProductsCardTwoTransition} timeout={500} classNames="fade">
          <ProductsCardZappyChat show={showProductsContent}><Img src={chatCardIcon} alt="chatCardIcon" /></ProductsCardZappyChat>
        </CSSTransition>
        <CSSTransition in={showProductsCardTwoTransition} timeout={300} classNames="fadeFour">
          <ProductsCardZappyChatTitle show={showProductsContent}>Levrg Chat</ProductsCardZappyChatTitle>
        </CSSTransition>
        <CSSTransition in={showProductsCardTwoTransition} timeout={300} classNames="fadeFour">
          <ProductsCardZappyChatHeading show={showProductsContent}>
            This tool is an AI-driven solution for appointment setting and customer service. It employs unique methods for versatile lead generation, allowing businesses to engage with potential customers in a more dynamic and personalized manner. Levrg Chat is designed to improve the interaction between businesses and their customers, providing a more efficient and effective way to handle customer inquiries and appointments.
          </ProductsCardZappyChatHeading>
        </CSSTransition>
      </ProductsCardContainer>
      <ProductsCardContainer active={activeProductCard === '3'}>
        <CSSTransition in={showProductsCardThreeTransition} timeout={500} classNames="fade">
          <ProductsCardZappyChat show={showProductsContent}><Img src={voiceCardIcon} alt="voiceCardIcon" /></ProductsCardZappyChat>
        </CSSTransition>
        <CSSTransition in={showProductsCardThreeTransition} timeout={300} classNames="fadeFour">
          <ProductsCardZappyChatTitle show={showProductsContent}>Levrg Voice</ProductsCardZappyChatTitle>
        </CSSTransition>
        <CSSTransition in={showProductsCardThreeTransition} timeout={300} classNames="fadeFour">
          <ProductsCardZappyChatHeading show={showProductsContent}>
            This AI tool is focused on calling and closing. It offers a programmable voice solution to manage any communication function within a business. Levrg Voice is capable of handling high-volume calls, providing intelligent lead management, and enhancing client interactions through realistic AI voice communication. This tool aims to revolutionize traditional sales outreach and customer service by automating and personalizing phone interactions.
          </ProductsCardZappyChatHeading>
        </CSSTransition>
      </ProductsCardContainer>
    </>
  )
}

export default ProductsCard