import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import "./App.css"
import LandingPage from './pages/landingPage';
import ComingSoonPage from './pages/comingSoonPage';

const App = () => {
  return (
    <Router>
    <Routes>  
      <Route
        path="/"
        exact
        element={<LandingPage />}
      /> 
      <Route
        path="/comingSoon"
        exact
        element={<ComingSoonPage />}
      /> 
    </Routes>
  </Router>
  )
}

export default App