import { Hamburger, HeaderContainer, Logo, Menu, MenuLink, NavbarContainer } from './headerStyles';
import { Img } from '../../reuseableComponents/globalStyles';
import landingPageLogo from '../../assets/images/landingPageLogo.png';
import styled from "styled-components";
import { Link } from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa'
import { useState } from 'react';

const MenuLinkButton = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
border-radius: 30px;
border: 3px solid #A855F7;
background: #A855F7;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
width: 150px;
height: 40px;
color: #FFF;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration: none;
cursor: pointer;

@media screen and (max-width: 990px) {
  width: 100px;
  height: 35px;
  font-size: 15px;
}
`;
const Header = ({scrollToTop, activeLink, handleSetActiveLink}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <HeaderContainer>
      <Logo onClick={scrollToTop}>
        <Img src={landingPageLogo} alt="landingPageLogo" />
        L E V R G
      </Logo>
      <NavbarContainer isOpen={isOpen}>
        <Hamburger onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FaTimes /> : <FaBars />}
        </Hamburger>
        <Menu isOpen={isOpen}>
          <MenuLink
            to="heroSection"
            spy={true}
            smooth={true}
            duration={10}
            activeClass="active"
            onSetActive={() => handleSetActiveLink('heroSection')}
            className={activeLink === 'heroSection' ? 'active' : ''}
          >
            Home
          </MenuLink>
          <MenuLink
            to="aboutUs"
            spy={true}
            smooth={true}
            duration={10}
            activeClass="active"
            onSetActive={() => handleSetActiveLink('aboutUs')}
            className={activeLink === 'aboutUs' ? 'active' : ''}
          >
            About Us
          </MenuLink>
          <MenuLink
            to="products"
            spy={true}
            smooth={true}
            duration={10}
            activeClass="active"
            onSetActive={() => handleSetActiveLink('products')}
            className={activeLink === 'products' ? 'active' : ''}
          >
            Products
          </MenuLink>
          <MenuLink
            to="pricing"
            spy={true}
            smooth={true}
            duration={10}
            activeClass="active"
            onSetActive={() => handleSetActiveLink('pricing')}
            className={activeLink === 'pricing' ? 'active' : ''}
          >
            Pricing
          </MenuLink>
          <MenuLink
            to="testimonials"
            spy={true}
            smooth={true}
            duration={10}
            activeClass="active"
            onSetActive={() => handleSetActiveLink('testimonials')}
            className={activeLink === 'testimonials' ? 'active' : ''}
          >
            Testimonials
          </MenuLink>
          <MenuLinkButton to="comingSoon">Login</MenuLinkButton>
        </Menu>
      </NavbarContainer>
    </HeaderContainer>
  );
};

export default Header;
