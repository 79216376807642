import React from "react";
import styled from "styled-components";
import testimonialsVideo from '../../assets/videos/testimonialsVideo.mp4';

const PopupBox = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  video{
    border-radius: 25px;
    border: none;
    outline: none;
  }
`;

const VideoPlayerCustom = () => {
  return (
    <PopupBox>
        <video
          controls
          width="100%"
          height="100%"
          src={testimonialsVideo}
          type="video/mp4"
        >
          Your browser does not support this video.
        </video>
    </PopupBox>
  );
};

export default VideoPlayerCustom;