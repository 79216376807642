import React, { useEffect, useState } from 'react'
import { PricingContainer, PricingHeading, PricingHeadingDifferent, PricingScrollContainer, PricingSubtitle, PricingTitle, PricingTitleUnderLine } from './pricingStyles'
import PricingMethods from './pricingMethods'
import { CSSTransition } from 'react-transition-group';

const Pricing = ({activeLink}) => {
  const [showPricingContent, setShowPricingContent] = useState(false);
  
  useEffect(() => {
      if (activeLink === 'pricing') {
          setShowPricingContent(true);
      }
    }, [activeLink]);

  return (
    <PricingScrollContainer id="pricing">
      <PricingContainer>
            <PricingTitle>Pricing<PricingTitleUnderLine /></PricingTitle>
          <CSSTransition in={showPricingContent} timeout={300} classNames="fadeThree">
            <PricingSubtitle show={showPricingContent}>Transparent and Value-Driven Pricing</PricingSubtitle>
          </CSSTransition>
          <CSSTransition in={showPricingContent} timeout={300} classNames="fadeFour">
            <PricingHeading show={showPricingContent}><PricingHeadingDifferent>Levrg</PricingHeadingDifferent> offers competitive pricing for our advanced AI services. Our pricing structure caters to various business needs, with options for custom builds, reseller support, and comprehensive AI bundles. Experience top-tier AI solutions.</PricingHeading>
          </CSSTransition>
          <PricingMethods showPricingContent={showPricingContent} />
      </PricingContainer>
    </PricingScrollContainer>
  )
}

export default Pricing