import styled from "styled-components";

export const TestimonialsScrollContainer = styled.div`
height: 1050px;
padding-top: 110px;

@media screen and (max-width: 1260px) {
    height: 1060px;
}
@media screen and (max-width: 990px) {
    height: 1050px;

}
@media screen and (max-width: 768px) {
    height: 1400px;
}
@media screen and (max-width: 450px) {
    height: 1300px;
}
`;
export const TestimonialsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;
width: 1220px;

@media screen and (max-width: 1260px) {
    width: 950px;
}
@media screen and (max-width: 990px) {
    width: 730px;
}
@media screen and (max-width: 768px) {
    width: 100vw;
}
`;
export const TestimonialsTitle = styled.h1`
font-size: 36px;
font-style: normal;
font-weight: bolder;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width: 990px) {
    font-size: 28px;
}
`;
export const TestimonialsTitleUnderLine = styled.div`
height: 7px;
width: 100%;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;
export const TestimonialsSubTitle = styled.h3`
display: ${({ show }) => (show ? 'block' : 'none')};
color: #0F172A;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
`;
export const TestimonialsHeading = styled.p`
display: ${({ show }) => (show ? 'block' : 'none')};
width: 984px;
color: #000;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;

@media screen and (max-width: 1260px) {
    width: 830px;
}
@media screen and (max-width: 990px) {
    width: 630px;
}
@media screen and (max-width: 768px) {
    width: 90%;
    text-align: justify;
}
`;
export const TestimonialsHeadingDifferent = styled.span`
background: linear-gradient(90deg, #9363FD 10.96%, #510295 13.31%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;
export const TestimonialsSwiperContainer = styled.div`
width: 100%;
.swiper {
  width: 100%;
  height: 100%;
  padding: 20px 50px;
}

.swiper-button-prev,
.swiper-button-next {
  width: 30px;
  height: 30px;
  border: 1px solid #6F2FFC;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 12px;
  color: #000;
}

`;
export const TestimonialsCard = styled.div`
width: 100%;
height: 360px;
border-radius: 25px;
border: 1px solid #6F2FFC;
background: #FFF;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: ${({ show }) => (show ? 'flex' : 'none')};
justify-content: space-between;
flex-direction: column;
padding: 20px;

@media screen and (max-width: 990px) {
    height: 400px;
}
@media screen and (max-width: 400px) {
    height: 420px;
    padding: 15px;
}
`;
export const TestimonialsCardTop = styled.div`
display: ${({ show }) => (show ? 'flex' : 'none')};
flex-direction: column;
gap: 15px;
`;
export const TestimonialsCardQoutes = styled.div`
align-self: flex-end;
`;
export const TestimonialsRatingStars = styled.div`
display: flex;
align-items: center;
gap: 5px;
`;
export const TestimonialsRatingStar = styled.div`
width: 30px;
height: 30px;
`;
export const TestimonialsCardHeading = styled.p`
color: #000;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: justify;
`;
export const TestimonialsCardBottom = styled.div`
display: ${({ show }) => (show ? 'flex' : 'none')};
flex-direction: column;
justify-content: flex-end;
gap: 20px;
`;
export const TestimonialsCardDivider = styled.div`
width: 100%;
height: 2px;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;
export const TestimonialsCardProfile = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
`;
export const TestimonialsCardProfileImage = styled.div`
width: 50px;
height: 50px;
border-radius: 200px;
border: 1px solid #6F2FFC;
display: flex;
justify-content: center;
align-items: center;
`;
export const TestimonialsCardProfileUserData = styled.div`
text-align: center;
h3{
color: #000;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
h4{
color: #B9BFCA;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
`;
export const TestimonialsVideoContainer = styled.div`
width: 1100px;
display: flex;
gap: 50px;

@media screen and (max-width: 1260px) {
    width: 930px;
    gap: 30px;
}
@media screen and (max-width: 990px) {
    width: 710px;
    gap: 20px;
}
@media screen and (max-width: 768px) {
    width: 90%;
    flex-direction: column;
}
`;
export const TestimonialsVideoCard = styled.div`
width: 341px;
height: 305px;
display: flex;
flex-direction: column;
justify-content: space-between;
gap: 10px;
margin-top: 10px;

@media screen and (max-width: 990px) {
    width: 240px;
    height: 270px;
}
@media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
}
`;

export const TestimonialsVideoCardHeading = styled.p`
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: normal;
background: linear-gradient(96deg, #6F2FFC 13.59%, #510295 48.1%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
width: 276px;
margin: 10px 0 0 10px;

@media screen and (max-width: 990px) {
    width: 200px;
    font-size: 20px;
}
`;