import styled from 'styled-components';

export const TeamContainer = styled.div`
perspective: 1000px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 40px;
width: 1220px;
padding: 0 20px;

@media screen and (max-width: 1260px) {
    width: 950px;
    padding: 0;
}
@media screen and (max-width: 990px) {
    width: 730px;
}
@media screen and (max-width: 990px) {
    width: 100%;
    align-items: center;
}
`;
export const TeamTitle = styled.h1`
font-size: 36px;
font-style: normal;
font-weight: bolder;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin-left: 10px;

@media screen and (max-width: 990px) {
    font-size: 28px;
}
`;
export const TeamTitleUnderLine = styled.div`
height: 7px;
width: 100%;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;
export const TeamContent = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr;
justify-content: space-between;
grid-gap: 40px;
width: 100%;

@media screen and (max-width: 1260px) {
 grid-template-columns: 1fr 1fr;
 column-gap: 230px;
}
@media screen and (max-width: 990px) {
 width: 750px;
 column-gap: 40px;
}
@media screen and (max-width: 768px) {
 grid-template-columns: 1fr;
 width: 350px;
 grid-gap: 25px;
}
@media screen and (max-width: 400px) {
 width: 330px;
 grid-gap: 20px;
}
`;
export const FlipCard = styled.div`
display: flex;
justify-content: center;
align-items: center;
background: none;
width: 350px;
height: 350px;
position: relative;
transition: transform 0.6s;
transform-style: preserve-3d;
&:hover .flip-card-inner {
transform: rotateY(180deg);
}

@media screen and (max-width: 400px) {
 width: 330px;
 height: 370px;
}
`;
export const FlipCardInner = styled.div`
position: relative;
width: 100%;
height: 100%;
transition: transform 0.6s;
transform-style: preserve-3d;
`;
export const FlipCardFront = styled.div`
position: absolute;
background: #FFF;
backface-visibility: hidden;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
width: 350px;
height: 350px;
border-radius: 25px;
border: 1px solid #000;
background: rgba(255, 255, 255, 0.00);
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

@media screen and (max-width: 400px) {
 width: 330px;
 height: 370px;
}
`;
export const TeamContentCardImage = styled.div`
width: 160px;
height: 154.712px;
border: 5px solid #6F2FFC;
border-radius: 360px;
display: flex;
justify-content: center;
align-items: center;
`;
export const TeamContentCardTitle = styled.h2`
color: #000;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
`;
export const TeamContentCardSubTitle = styled.h3`
color: #000;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-align: center;
`;
export const FlipCardBack = styled.div`
position: absolute;
width: 100%;
height: 100%;
background-color: #2980b9;
color: white;
backface-visibility: hidden;
transform: rotateY(180deg);
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 350px;
height: 350px;
border-radius: 25px;
border: 1px solid #7F23E6;
background: #7F23E6;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 0 15px;

@media screen and (max-width: 400px) {
 width: 330px;
 height: 370px;
}
`;
export const TeamContentCardHeading = styled.p`
color: #FFF;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: justify;
`;