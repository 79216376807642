import React, { useEffect, useState } from 'react'
import productsBackground from '../../assets/images/productsBackground.png'
import { Img } from '../../reuseableComponents/globalStyles'
import { ProductsContainer, ProductsContent, ProductsContentBackground, ProductsContentCard, ProductsHeading, ProductsHeadingDifferent, ProductsScrollContainer, ProductsSubTitle, ProductsTitle, ProductsTitleUnderLine } from './productsStyles'
import ProductsFilterBar from './productsFilterBar'
import ProductsCard from './productsCard'
import { CSSTransition } from 'react-transition-group';

const Products = ({activeLink}) => {
  const [activeProductCard, setActiveProductCard] = useState('1');
  const [showProductsContent, setShowProductsContent] = useState(false);

  useEffect(() => {
    if (activeLink === 'products') {
        setShowProductsContent(true);
    }
  }, [activeLink]);

  return (
    <ProductsScrollContainer id="products">
      <ProductsContainer>
            <ProductsTitle>Products<ProductsTitleUnderLine /></ProductsTitle>
          <CSSTransition in={showProductsContent} timeout={300} classNames="fadeThree">
            <ProductsSubTitle show={showProductsContent}>Our Innovative Products</ProductsSubTitle>
          </CSSTransition>
          <CSSTransition in={showProductsContent} timeout={300} classNames="fadeFour">
            <ProductsHeading show={showProductsContent}>
                Discover <ProductsHeadingDifferent> Levrg's suite </ProductsHeadingDifferent> of AI-powecred products, designed to revolutionize your business operations. From Levrg Social for campaign launch and lead generation to Levrg Chat for appointment setting and customer service, and Levrg Voice for advanced calling and closing solutions, each product is engineered to automate and optimize your client acquisition process.
            </ProductsHeading>
          </CSSTransition>
          <ProductsContent>
              <ProductsContentBackground><Img src={productsBackground} alt="productsBackground" /></ProductsContentBackground>
              <ProductsContentCard>
                <ProductsFilterBar setActiveProductCard={setActiveProductCard} showProductsContent={showProductsContent}/>
                <ProductsCard activeProductCard={activeProductCard} showProductsContent={showProductsContent} activeLink={activeLink}/>
              </ProductsContentCard>
          </ProductsContent>
      </ProductsContainer>
    </ProductsScrollContainer>
  )
}

export default Products