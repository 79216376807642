import React, { useState, useEffect } from 'react';
import { ComingSoonCountBox, ComingSoonCountBoxHeading, ComingSoonCountBoxValue, ComingSoonCountContainer } from './comingSoonStyles';

const Countdown = () => {
  const calculateTimeLeft = () => {
    const deadline = new Date('2024-01-25').getTime(); // Change this to your deadline date
    const currentTime = new Date().getTime();
    const difference = deadline - currentTime;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <ComingSoonCountContainer>
        <ComingSoonCountBox>
            <ComingSoonCountBoxValue>{timeLeft.days}</ComingSoonCountBoxValue>
            <ComingSoonCountBoxHeading>Days{' '}</ComingSoonCountBoxHeading>
        </ComingSoonCountBox>
        <ComingSoonCountBox>
            <ComingSoonCountBoxValue>{timeLeft.hours}</ComingSoonCountBoxValue>
            <ComingSoonCountBoxHeading>Hours{' '}</ComingSoonCountBoxHeading>
        </ComingSoonCountBox>
        <ComingSoonCountBox>
            <ComingSoonCountBoxValue>{timeLeft.minutes}</ComingSoonCountBoxValue>
            <ComingSoonCountBoxHeading>Minutes{' '}</ComingSoonCountBoxHeading>
        </ComingSoonCountBox>
        <ComingSoonCountBox>
            <ComingSoonCountBoxValue>{timeLeft.seconds}</ComingSoonCountBoxValue>
            <ComingSoonCountBoxHeading>Seconds{' '}</ComingSoonCountBoxHeading>
        </ComingSoonCountBox>
    </ComingSoonCountContainer>
  );
};

export default Countdown;
