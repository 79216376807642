import React from 'react'
import { ComingSoonContainer, ComingSoonContent, ComingSoonLeftContent, ComingSoonTop, ComingSoonTopbar, ComingSoonButton, ComingSoonLeftContentTitle, ComingSoonLeftContentHeading, ComingSoonRightContent, ComingSoonBottom, ComingSoonRights, ComingSoonRightsIcon, ComingSoonRightsHeading } from './comingSoonStyles'
import Countdown from './countDown'
import comingSoonLogo from '../../assets/images/comingSoonLogo.png'
import { Img } from '../../reuseableComponents/globalStyles'

const ComingSoon = () => {
  return (
    <ComingSoonContainer>
        <ComingSoonTop>
            <ComingSoonTopbar>
                <Img src={comingSoonLogo} alt="comingSoonLogo" />
                <ComingSoonButton to='/'>Go Back</ComingSoonButton>
            </ComingSoonTopbar>
            <ComingSoonContent>
                <ComingSoonLeftContent>
                    <ComingSoonLeftContentTitle>
                        Why So Excited?
                    </ComingSoonLeftContentTitle>
                    <ComingSoonLeftContentHeading>
                        Levrg is launching some crazy products. <br /> 
                        Stay Tuned!
                    </ComingSoonLeftContentHeading>
                    <ComingSoonButton to='/'>Go Back</ComingSoonButton>
                </ComingSoonLeftContent>
                <ComingSoonRightContent>
                    <Countdown />
                </ComingSoonRightContent>
            </ComingSoonContent>
        </ComingSoonTop>
        <ComingSoonBottom>
            <ComingSoonRights>
                <ComingSoonRightsIcon>C</ComingSoonRightsIcon>
                <ComingSoonRightsHeading>Copyright. All rights reserved.</ComingSoonRightsHeading>
            </ComingSoonRights>
        </ComingSoonBottom>
    </ComingSoonContainer>
  )
}

export default ComingSoon