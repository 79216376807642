import styled from "styled-components";
import { Link } from 'react-router-dom';

export const ComingSoonContainer = styled.div`
background: rgba(255, 255, 255, 0.50);
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
gap: 100px;
padding: 20px 5rem;
min-height: 100vh;
height: 100%;
 
@media screen and (max-width: 1050px) {
    padding: 20px 50px;
}
@media screen and (max-width: 768px) {
    padding: 20px;
}
`;
export const ComingSoonTop = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 100px;
width: 100%;
`;
export const ComingSoonTopbar = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;

@media screen and (max-width: 768px) {
    img{
        width: 150px;
    }
}
`;
export const ComingSoonButton = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
text-decoration: none;
width: 169px;
height: 50px;
border-radius: 30px;
border: 3px solid #A855F7;
background: #A855F7;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
outline: none;
cursor: pointer;
color: #FFF;
font-size: 20px;
font-weight: 700;
line-height: normal;

@media screen and (max-width: 768px) {
    width: 140px;
    height: 40px;
    font-size: 16px;
}
`;
export const ComingSoonContent = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
gap: 40px;
width: 100%;

@media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
}
`;
export const ComingSoonLeftContent= styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 30px;
`;
export const ComingSoonLeftContentTitle= styled.h1`
font-size: 40px;
font-weight: 900;
line-height: normal;
background: linear-gradient(92deg, #6E06F9 42.51%, #4D0D8C 77.56%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width: 990px) {
    font-size: 30px;
}
`;
export const ComingSoonLeftContentHeading= styled.p`
color: #000;
font-size: 24px;
font-weight: 500;
line-height: normal;

@media screen and (max-width: 990px) {
    font-size: 18px;
}
@media screen and (max-width: 768px) {
    font-size: 16px;
}
`;
export const ComingSoonRightContent = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;
export const ComingSoonCountContainer = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
gap: 50px;

@media screen and (max-width: 990px) {
    gap: 30px;
}
`;
export const ComingSoonCountBox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;
export const ComingSoonCountBoxValue = styled.h1`
color: #000;
font-size: 48px;
font-weight: 900;
line-height: normal;

@media screen and (max-width: 990px) {
    font-size: 30px;
}
`;
export const ComingSoonCountBoxHeading = styled.p`
color: #000;
font-size: 16px;
font-weight: 400;
line-height: normal;

@media screen and (max-width: 990px) {
    font-size: 14px;
}
`;
export const ComingSoonBottom = styled.div`
display: flex;
justify-content: flex-end;
align-items: flex-end;
width: 100%;
margin-top: 20px;
`;
export const ComingSoonRights = styled.div`
display: flex;
align-items: center;
gap: 5px;
`;
export const ComingSoonRightsIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 15px;
height: 15px;
border: 1px solid #000;
border-radius: 360px;
color: #000;
font-size: 12px;
font-weight: 400;
line-height: normal;
`;
export const ComingSoonRightsHeading = styled.p`
color: #000;
font-size: 16px;
font-weight: 400;
line-height: normal;
`;