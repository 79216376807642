import React, { useState, useEffect } from 'react'
import Header from '../header/header'
import { LandingPageContainer } from './landingPageSetupStyles'
import HeroSection from '../heroSection/heroSection'
import AboutUs from '../aboutUs/aboutUs'
import Products from '../products/products'
import Pricing from '../pricing/pricing'
import Testimonials from '../testimonials/testimonials'
import Footer from '../footer/footer'
import { animateScroll as scroll } from 'react-scroll';

const LandingPageSetup = () => {
  const [activeLink, setActiveLink] = useState('heroSection'); // State to track the active link
  const scrollToTop = () => {
    scroll.scrollToTop();
  };  
  // Function to set active link based on scroll position
  const handleSetActiveLink = (link) => {
    setActiveLink(link);
  };

  // Function to handle scroll and set active link accordingly
  const handleScroll = () => {
    const sections = ['heroSection', 'aboutUs', 'products', 'pricing', 'testimonials']; // Add section IDs here
    const scrollPosition = window.scrollY + 20; // Adjust for accurate scroll position
    sections.forEach((section) => {
      const element = document.getElementById(section);
      if (element) {
        const offsetTop = element.offsetTop;
        const offsetBottom = offsetTop + element.offsetHeight;
        if (scrollPosition >= offsetTop && scrollPosition < offsetBottom) {
          setActiveLink(section);
        }
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeLink]);
  

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeLink]);
  return (
    <LandingPageContainer>
      <Header scrollToTop={scrollToTop} activeLink={activeLink} handleSetActiveLink={handleSetActiveLink}/>
      <HeroSection activeLink={activeLink}/>
      <AboutUs activeLink={activeLink} />
      <Products activeLink={activeLink} />
      <Pricing activeLink={activeLink} />
      <Testimonials activeLink={activeLink} />
      <Footer scrollToTop={scrollToTop} handleSetActiveLink={handleSetActiveLink}/>
    </LandingPageContainer>
  )
}

export default LandingPageSetup