import React, { useEffect, useState } from 'react'
import LandingPageSetup from '../components/landingPageSetup/landingPageSetup'
import LoaderComponent from '../components/loader/loaderComponent';

const LandingPage = () => {
  
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3000);
  
      return () => clearTimeout(timer);
    }, []);

  return (
    <>
      {loading ? (
        <LoaderComponent />
        ) : (
          <LandingPageSetup />
        )}
      </>
  )
}

export default LandingPage