import React from 'react'
import footerLogo from '../../assets/images/footerLogo.png'
import { Img } from '../../reuseableComponents/globalStyles'
import { FooterContainer, FooterDetail, FooterLink, FooterLinks, FooterLinksContainer, FooterLogoDetail, FooterLogoHeading, FooterRights, FooterRightsContainer, FooterRightsDivider, FooterRightsHeading, FooterRightsIcon, FooterSubscriber, FooterSubscriberButton, FooterSubscriberHeading, FooterSubscriberInput } from './footerStyles'

const Footer = ({scrollToTop, handleSetActiveLink}) => {
  return (
    <FooterContainer>
        <FooterDetail>
            <FooterLogoDetail>
                <Img src={footerLogo} alt="footerLogo" onClick={scrollToTop} />
                <FooterLogoHeading>
                    <h3>L E V R G</h3>
                    <h4>“Work Less, Profit More”</h4>
                </FooterLogoHeading>
            </FooterLogoDetail>
            <FooterLinksContainer>
            <FooterLinks>
                    <FooterLink
                        to="heroSection"
                        spy={true}
                        smooth={true}
                        duration={10}
                        onSetActive={() => handleSetActiveLink('heroSection')}
                    >
                        HOME <p></p>
                    </FooterLink>
                    <FooterLink
                        to="aboutUs"
                        spy={true}
                        smooth={true}
                        duration={10}
                        onSetActive={() => handleSetActiveLink('aboutUs')}
                    >
                        ABOUT US <p></p>
                    </FooterLink>
                    <FooterLink
                        to="products"
                        spy={true}
                        smooth={true}
                        duration={10}
                        onSetActive={() => handleSetActiveLink('products')}
                    >
                        PRODUCTS <p></p>
                    </FooterLink>
                </FooterLinks>
                <FooterLinks>
                    <FooterLink
                        to="pricing"
                        spy={true}
                        smooth={true}
                        duration={10}
                        onSetActive={() => handleSetActiveLink('pricing')}
                    >
                        PRICING <p></p>
                    </FooterLink>
                    <FooterLink
                        to="testimonials"
                        spy={true}
                        smooth={true}
                        duration={10}
                        onSetActive={() => handleSetActiveLink('testimonials')}
                    >
                        TESTIMONIALS <p></p>
                    </FooterLink>
                </FooterLinks>
            </FooterLinksContainer>
            <FooterSubscriber>
                <FooterSubscriberHeading>
                    Subscribe to our email list and we’ll
                    keep you informed of new Weekly
                    theme release, updates. exclusive 
                    deals and Weekly tutorials.
                </FooterSubscriberHeading>
                <FooterSubscriberInput
                type='text'
                placeholder='YOUR EMAIL'
                />
                <FooterSubscriberButton>SUBMIT</FooterSubscriberButton>
            </FooterSubscriber>
        </FooterDetail>
        <FooterRightsContainer>
            <FooterRights>
                <FooterRightsIcon>C</FooterRightsIcon>
                <FooterRightsHeading>Copyright. All rights reserved.</FooterRightsHeading>
            </FooterRights>
            <FooterRightsDivider />
        </FooterRightsContainer>
    </FooterContainer>
  )
}

export default Footer