import React, {useState} from 'react'
import styled from 'styled-components'
import videoFemale from '../../assets/images/videoFemale.jpeg'
import videoPlayButton from '../../assets/images/videoPlayButton.png'
import { Img } from '../../reuseableComponents/globalStyles';
import VideoPlayerCustom from './videoPlayerCustom';

const VideoSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 650px;
  height: 353px;
  border-radius: 25px;
  img{
    border-radius: 25px;
    width: 100%;
    height: 100%;
  }
  
@media screen and (max-width: 1260px) {
    width: 550px;
}
@media screen and (max-width: 990px) {
    width: 450px;
    height: 300px;
}
@media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
}
`;
const VideoPlayerIcon = styled.div`
 display: flex;
 justify-content: center;
 align-items: center;
 position: absolute;
 img{
 cursor: pointer;
 width: 60px;
height: 61.126px;
 }
`;

const TestimonialsVideo = () => {
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
      }

    return (
        <VideoSection>
            <Img src={videoFemale} alt='videoFemale' />
		  <VideoPlayerIcon>
            <Img  onClick={togglePopup} src={videoPlayButton} alt='videoPlayButton' />
		  </VideoPlayerIcon>
          {isOpen && <VideoPlayerCustom />}
        </VideoSection>
    )
}

export default TestimonialsVideo
