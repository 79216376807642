import React from 'react';
import { Img } from '../../reuseableComponents/globalStyles'
import { teamMembers } from './memberData';
import { FlipCard, FlipCardBack, FlipCardFront, FlipCardInner, TeamContainer, TeamContent, TeamContentCardHeading, TeamContentCardImage, TeamContentCardSubTitle, TeamContentCardTitle, TeamTitle, TeamTitleUnderLine } from './teamStyles';



const Team = () => {
  return (
    <TeamContainer>
        <TeamTitle>Meet Our Team <TeamTitleUnderLine /> </TeamTitle>
        <TeamContent>
        {teamMembers.map((member) => (
        <FlipCard>
            <FlipCardInner className="flip-card-inner">
                <FlipCardFront>
                        <TeamContentCardImage><Img src={member.image} alt="img" /></TeamContentCardImage>
                        <TeamContentCardTitle>{member.name}</TeamContentCardTitle>
                        <TeamContentCardSubTitle>{member.position}</TeamContentCardSubTitle>
                </FlipCardFront>
                <FlipCardBack>
                    <TeamContentCardHeading>
                        {member.description}
                    </TeamContentCardHeading>
                </FlipCardBack>
            </FlipCardInner>
        </FlipCard>
        ))}
      </TeamContent>
    </TeamContainer>
  );
};

export default Team;
