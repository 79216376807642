// CircularLoader.js
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Img } from '../../reuseableComponents/globalStyles';
import loaderImage from '../../assets/images/loaderImage.png';
import loaderLogo from '../../assets/images/loaderLogo.png';

// Keyframe animation for rotating only the border
const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  width: 250px;
  height: 250px;
  position: relative;
  
@media screen and (max-width: 768px) {
    width: 150px;
    height: 150px;
}
`;

const Circle = styled.div`
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: ${spin} 3s linear infinite;
  img{
    height: 100%;
    width: 100%;
  }
  
@media screen and (max-width: 768px) {
    width: 160px;
    height: 160px;
}
`;

const fadeInLeft = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Content = styled.div`
  position: relative;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
   
@media screen and (max-width: 768px) {
    width: 160px;
    height: 160px;
}
`;

const ContentImage = styled.div`
  width: 64.824px;
  height: 57px;
  animation: ${fadeInLeft} 0.5s ease-in-out forwards;
   
@media screen and (max-width: 768px) {
    width: 35px;
    height: 30px;
}
`;

const ContentHeadingOne = styled.p`
color: #0F172A;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
animation: ${fadeInLeft} 1s ease-in-out forwards;
 
@media screen and (max-width: 768px) {
    font-size: 10px;
}
`;

const ContentHeadingTwo = styled.p`
color: #0F172A;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
animation: ${fadeInLeft} 1.5s ease-in-out forwards;

@media screen and (max-width: 768px) {
    font-size: 10px;
}
`;

const ContentHeadingThree = styled.p`
color: #0F172A;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
animation: ${fadeInLeft} 2s ease-in-out forwards;

@media screen and (max-width: 768px) {
    font-size: 10px;
}
`;

const ContentHeadingFour = styled.p`
color: #0F172A;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
animation: ${fadeInLeft} 2.5s ease-in-out forwards;

@media screen and (max-width: 768px) {
    font-size: 10px;
}
`;

const ContentHeadingFive = styled.p`
color: #0F172A;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
animation: ${fadeInLeft} 3s ease-in-out forwards;

@media screen and (max-width: 768px) {
    font-size: 10px;
}
`;

const LoaderComponent = () => {
  return (
    <LoaderContainer>
        <Loader>
            <Circle>
                <img src={loaderImage} alt="loaderImage" />
            </Circle>
          <Content>
                <ContentImage>
                    <Img src={loaderLogo} alt="loaderLogo" />
                </ContentImage>
                <ContentHeadingOne>L</ContentHeadingOne>
                <ContentHeadingTwo>E</ContentHeadingTwo>
                <ContentHeadingThree>V</ContentHeadingThree>
                <ContentHeadingFour>R</ContentHeadingFour>
                <ContentHeadingFive>G</ContentHeadingFive>
            </Content>
        </Loader>
    </LoaderContainer>
  )
};

export default LoaderComponent;
