import { Link } from "react-scroll";
import styled from "styled-components";

export const FooterContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
gap: 100px;
width: 1220px;
padding: 50px 20px;
margin-top: 100px;
@media screen and (max-width: 1260px) {
    width: 950px;
}
@media screen and (max-width: 990px) {
    width: 730px;
}
@media screen and (max-width: 768px) {
    width: 90%;
}
`;
export const FooterDetail = styled.div`
display: flex;
justify-content: space-between;
gap: 30px;

@media screen and (max-width: 768px) {
    flex-direction: column;
}
`;
export const FooterLogoDetail = styled.div`
display: flex;
align-items: flex-start;
gap: 10px;
img{cursor: pointer;}

@media screen and (max-width: 990px) {
    img{width: 80px;}
}
`;
export const FooterLogoHeading = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
margin-top: 30px;
h3{
color: #0F172A;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
h4{
font-size: 20px;
font-style: normal;
font-weight: 800;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

@media screen and (max-width: 990px) {
    h3{
        font-size: 15px;
    }
    h4{
        font-size: 15px;
    }
}
`;
export const FooterLinksContainer = styled.div`
display: flex;
gap: 100px;

@media screen and (max-width: 1260px) {
    gap: 30px;
}
@media screen and (max-width: 990px) {
    flex-direction: column;
}
@media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
}
`;
export const FooterLinks = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
`;
export const FooterLink = styled(Link)`
color: #000;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
p{
    width: 100%;
    height: 1px;
}
&:hover{
    p{
        background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
    }
}
`;
export const FooterSubscriber = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
width: 278px;

@media screen and (max-width: 990px) {
    width: 230px;
}
@media screen and (max-width: 768px) {
    width: 90%;
    padding: 0 20px;
}
`;
export const FooterSubscriberHeading = styled.p`
color: #000;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
export const FooterSubscriberInput = styled.input`
outline: none;
height: 50px;
width: 265px;
border-radius: 30px;
border: 1px solid #A855F7;
background: rgba(168, 85, 247, 0.00);
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
padding: 0 20px;
color: #000;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;

::placeholder{
color: #000;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

@media screen and (max-width: 990px) {
    width: 220px;
}
@media screen and (max-width: 768px) {
    width: 90%;
    height: 40px;
}
`;
export const FooterSubscriberButton = styled.button`
outline: none;
cursor: pointer;
width: 169px;
height: 50px;
border-radius: 30px;
border: 3px solid #A855F7;
background: #A855F7;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;

@media screen and (max-width: 768px) {
    height: 40px;
}
`;
export const FooterRightsContainer = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
`;
export const FooterRights = styled.div`
display: flex;
gap: 5px;
`;
export const FooterRightsIcon = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 15px;
height: 15px;
border: 1px solid #000;
border-radius: 360px;
color: #000;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
export const FooterRightsHeading = styled.p`
color: #000;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;
export const FooterRightsDivider = styled.div`
width: 100%;
height: 2px;
border: 2px solid #000;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;