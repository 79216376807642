import Joshua from '../../assets/images/Joshua.png'
import Kevin from '../../assets/images/Kevin.png'
import Ashan from '../../assets/images/Ashan.png'
import Abdus from '../../assets/images/Abdus.png'
import Christopher from '../../assets/images/Christopher.png'
import Juan from '../../assets/images/Juan.png'
export const teamMembers = [
    {
      id: '1',
      name: 'Joshua Collier',
      position: 'Co-Founder & CEO',
      image: Joshua,
      description:
        "Meet Joshua Collier, our visionary Co-Founder and CEO at Levrg. With a robust background in business innovation, Joshua brings a wealth of expertise to his role. A graduate in Business Innovation & Entrepreneurship from MTSU, he has a deep understanding of market dynamics and an unyielding passion for AI technology. His journey includes a successful five-year tenure in Wealth Management and building a thriving Insurance Agency. Known for his exceptional leadership skills, Joshua has not only led a 7-figure sales team but also has a proven track record in building and coaching 7 & 8 figure sales teams, especially in the online realm. His strategic vision and entrepreneurial spirit are the driving forces propelling Levrg towards new heights."
    },
    {
      id: '2',
      name: 'Kevin Jacobson Jr.',
      position: 'Co-Founder & Chief Strategy Officer',
      image: Kevin,
      description:
        "Introducing Kevin Jacobson Jr., the strategic mastermind and Co-Founder at Levrg. As our Chief Strategy Officer, Kevin is instrumental in steering the company's direction towards groundbreaking opportunities and innovative solutions. His extensive experience as an Instagram and Influencer Marketing Expert complements his strategic role, ensuring Levrg stays ahead in the digital landscape. Beyond his professional achievements, Kevin's life is rich with personal joy. He is married to his high school sweetheart, and together they nurture a loving family with three children. Kevin’s unique blend of professional acumen and personal values significantly enriches our team and our company's culture."
    },
    {
      id: '3',
      name: 'Ashan Dharmasiri',
      position: 'Chief Operations Officer',
      image: Ashan,
      description:
        "Meet Ashan Dharmasiri, our Chief Operations Officer at Levrg, where his leadership ensures the smooth and efficient functioning of all operational processes. Ashan's role is pivotal in maintaining the high standards and effectiveness of Levrg's diverse services. Beyond his professional prowess, Ashan is a man of remarkable personal achievements. He is an internationally rated chess player and a FOA Chess FIDE Master, demonstrating strategic brilliance and analytical acumen. His skill in the game is further highlighted by his status as a two-time State Chess Champion. Ashan is also a champion swimmer, showcasing his dedication and discipline. Adding to this impressive list, he is a Junior Science Olympiads Winner, reflecting his broad spectrum of knowledge and passion for learning. Ashan's blend of operational expertise and diverse personal accomplishments make him an invaluable asset to our team and a key driver of Levrg's success."
    },
    {
      id: '4',
      name: 'Abdus Samad',
      position: 'Chief Technology Officer',
      image: Abdus,
      description:
        "As CTO, Abdus spearheads the technological innovation at Levrg. His expertise is central to the development and implementation of our advanced AI solutions."
    },
    {
      id: '5',
      name: 'Juan Urena',
      position: 'Client Success Manager',
      image: Juan,
      description:
        "As Client Success Manager, Juan is dedicated to ensuring the success and satisfaction of our clients. His role is pivotal in building and maintaining strong customer relationships."
    },
    {
      id: '6',
      name: 'Christopher Doyle',
      position: 'Sales Manager',
      image: Christopher,
      description:
        "As Sales Manager, Christopher leads our sales team with a focus on strategy and growth. His leadership is instrumental in driving our sales initiatives and achieving business goals."
    },
  ];
  