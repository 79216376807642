import styled from "styled-components";
import { FaCheck } from "react-icons/fa6";

export const AboutUsScrollContainer = styled.div`
height: 1660px;
padding-top: 110px;

@media screen and (max-width: 1260px) {
    height: 1950px;
}
@media screen and (max-width: 990px) {
    padding-top: 80px;
    width: 100%;
    height: 1830px;
}
@media screen and (max-width: 768px) {
    height: 3220px;
}
@media screen and (max-width: 400px) {
    height: 3320px;
}
`;
export const AboutUsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 40px;
width: 1220px;
padding: 0 20px;

@media screen and (max-width: 1260px) {
    width: 100%;
}
`;
export const AboutUsTitle = styled.h1`
font-size: 36px;
font-style: normal;
font-weight: 800;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width: 990px) {
    font-size: 28px;
}
`;
export const AboutUsTitleUnderLine = styled.div`
height: 7px;
width: 100%;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;
export const AboutUsContent = styled.div`
width: 1120px;
height: 100%;
display: flex;
justify-content: flex-end;
position: relative;

@media screen and (max-width: 1260px) {
    width: 950px;
}
@media screen and (max-width: 990px) {
    width: 730px;
}
@media screen and (max-width: 768px) {
    justify-content: center;
    width: 100%;
}
`;
export const AboutUsContentBackground = styled.div`
width: 650px;
height: 570px;
border-radius: 25px;
border: 0px solid rgba(0, 0, 0, 0.00);
background: #7F23E6;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

@media screen and (max-width: 1260px) {
    width: 560px;
    height: 470px;
}
@media screen and (max-width: 990px) {
    width: 450px;
    height: 390px;
}
@media screen and (max-width: 768px) {
    width: 100%;
    height: 700px;
}
`;
export const AboutUsContentCard = styled.div`
border-radius: 25px;
border: 1px solid #6F2FFC;
background: #FFF;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
position: absolute;
top: 85px;
right: 70px;
left: 0;
bottom: 0;
display: flex;
gap: 26px;
img{
display: ${({ show }) => (show ? 'flex' : 'none')};
border-radius: 25px 0 0 25px;
width: 46%;
}

@media screen and (max-width: 1260px) {
    top: 60px;
    right: 53px;
}
@media screen and (max-width: 990px) {
    img{
        width: 42%;
    }
}
@media screen and (max-width: 768px) {
    top: 20px;
    right: 20px;
    left: 20px;
    bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    img{
        vertical-align: top;
        max-width: 100%;
        min-width: 230px;
        height: auto;
        border-radius: 25px;
    }
}
`;
export const AboutUsContentCardRight = styled.div`
display: ${({ show }) => (show ? 'flex' : 'none')};
gap: 10px;
flex-direction: column;
padding-right: 32px;
width: 54%;

@media screen and (max-width: 990px) {
    width: 58%;
}
@media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
}
`;
export const AboutUsContentCardRightTitle = styled.h3`
font-size: 28px;
font-style: normal;
font-weight: 800;
line-height: normal;
text-align: center;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
margin: 20px 0 40px 0;

@media screen and (max-width: 990px) {
    font-size: 24px;
    margin: 15px 0 20px 0;
}
@media screen and (max-width: 990px) {
    font-size: 22px;
    margin: 10px 0 10px 0;
}
`;
export const AboutUsContentCardRightProperties = styled.div`
display: flex;
flex-direction: column;
gap: 30px;

@media screen and (max-width: 1260px) {
    gap: 20px;
}
@media screen and (max-width: 990px) {
    gap: 15px;
}
`;
export const AboutUsContentCardRightProperty = styled.div`
display: flex;
gap: 10px;
`;
export const AboutUsContentCardRightPropertyIconContainer = styled.div`
width: 20px;
height: 20px;
background: #7F23E6;
border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
padding: 2px;
margin-top: 3px;

@media screen and (max-width: 1260px) {
    width: 18px;
    height: 18px;
}
@media screen and (max-width: 990px) {
    width: 15px;
    height: 15px;
}
`;
export const AboutUsContentCardRightPropertyIcon = styled(FaCheck)`
color: #FFF;
width: 15px;
height: 15px;
`;
export const AboutUsContentCardRightPropertyHeading = styled.p`
color: #242048;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;

@media screen and (max-width: 1260px) {
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    font-size: 16px;
}
`;
export const AboutUsContentCardRightPropertyHeadingDifferent = styled.span`
background: linear-gradient(94deg, #6F2FFC 40.66%, #510295 51.94%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;

@media screen and (max-width: 1260px) {
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    font-size: 16px;
}
`;