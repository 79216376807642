import styled from "styled-components";

export const HeroScrollContainer = styled.div`
height: 520px;
padding: 90px 20px 0 20px;
@media screen and (max-width: 1260px) {
    height: 460px;
}
@media screen and (max-width: 990px) {
    height: 380px;
    padding: 80px 20px 0 20px;
}
@media screen and (max-width: 768px) {
    height: 650px;
}
`;
export const HeroContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
gap: 20px;
width: 1230px;

@media screen and (max-width: 1260px) {
    width: 100%;
    width: 950px;
}
@media screen and (max-width: 990px) {
    width: 730px;
    gap: 10px;
}
@media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    gap: 20px;
}
`;
export const HeroLeftPart = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
width: 702px;

@media screen and (max-width: 1260px) {
    width: 65%;
}
@media screen and (max-width: 1260px) {
    gap: 20px;
}
@media screen and (max-width: 768px) {
    width: 95%;
    gap: 15px;
}
`;
export const HeroTitle = styled.h1`
font-size: 68px;
font-style: normal;
font-weight: bolder;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width: 1260px) {
    font-size: 55px;
}
@media screen and (max-width: 990px) {
    font-size: 40px;
}
@media screen and (max-width: 768px) {
    font-size: 30px;
}
`;
export const HeroSubTitle = styled.p`
color: #242048;
font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;

@media screen and (max-width: 1260px) {
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    display: none;
}
`;
export const HeroSubTitleMobileView = styled.p`
display: none;
color: #242048;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: justify;

@media screen and (max-width: 768px) {
    display: block;
}
`;
export const HeroHeading = styled.h1`
color: #242048;
font-size: 22px;
font-style: normal;
font-weight: 800;
line-height: normal;

@media screen and (max-width: 1260px) {
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    font-size: 14px;
}
`;
export const HeroHeadingDifferent = styled.span`
background: linear-gradient(99deg, #6F2FFC 81.27%, #510295 85.93%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 22px;
font-style: normal;
font-weight: 800;
line-height: normal;

@media screen and (max-width: 1260px) {
    font-size: 20px;
}
@media screen and (max-width: 990px) {
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    font-size: 14px;
}
`;
export const HeroRightPart = styled.div`
width: 390px;
height: 351.379px;

@media screen and (max-width: 1260px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;
}
@media screen and (max-width: 768px) {
    width: 90%;
}
`;