import React  from 'react';
import testimonialsQoutes from '../../assets/images/testimonialsQoutes.png'
import filledStar from '../../assets/images/filledStar.png'
// import john from '../../assets/images/john.png'
// import adam from '../../assets/images/adam.png'
// import merlin from '../../assets/images/merlin.png'
import { Img } from '../../reuseableComponents/globalStyles'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Mousewheel, Keyboard } from 'swiper/modules';
import { CSSTransition } from 'react-transition-group';
import { TestimonialsCard, TestimonialsCardTop, TestimonialsCardBottom, TestimonialsCardDivider, TestimonialsCardHeading, TestimonialsCardProfile, TestimonialsCardProfileUserData, TestimonialsCardQoutes, TestimonialsRatingStar, TestimonialsRatingStars, TestimonialsSwiperContainer } from './testimonialsStyle';

const TestimonialsSwiper = ({showTestimonialsContent}) => {
  return (
    <TestimonialsSwiperContainer>
    <Swiper
      spaceBetween={30}
      cssMode={true}
      navigation={true}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Mousewheel, Keyboard]}
      className="mySwiper"
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1260: {
          slidesPerView: 3,
        },
      }}
    >
      <SwiperSlide>
        <CSSTransition in={showTestimonialsContent} timeout={1000} classNames="fadeLeftToRightOne">
            <TestimonialsCard show={showTestimonialsContent}>
                <TestimonialsCardTop show={showTestimonialsContent}>
                    <TestimonialsCardQoutes>
                            <Img src={testimonialsQoutes} alt="testimonialsQoutes" />
                    </TestimonialsCardQoutes>
                    <TestimonialsRatingStars>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    </TestimonialsRatingStars>
                    <TestimonialsCardHeading>
                        Hi. I just tried it on one example and I can tell you that it's great! I really love it! It really helps me ask better questions! Thank you so much!it really teaches me how to ask better questions and go deeper! muchas gracias
                    </TestimonialsCardHeading>
                </TestimonialsCardTop>
                <TestimonialsCardBottom show={showTestimonialsContent}>
                    <TestimonialsCardDivider />
                    <TestimonialsCardProfile>
                        {/* <TestimonialsCardProfileImage><Img src={john} alt="john" /></TestimonialsCardProfileImage> */}
                        <TestimonialsCardProfileUserData>
                            <h3>Lucia Krutekova</h3>
                        </TestimonialsCardProfileUserData>
                    </TestimonialsCardProfile>
                </TestimonialsCardBottom>
            </TestimonialsCard>
        </CSSTransition>
      </SwiperSlide>
      <SwiperSlide>
        <CSSTransition in={showTestimonialsContent} timeout={2000} classNames="fadeLeftToRightTwo">
            <TestimonialsCard show={showTestimonialsContent}>
                <TestimonialsCardTop show={showTestimonialsContent}>
                    <TestimonialsCardQoutes>
                            <Img src={testimonialsQoutes} alt="testimonialsQoutes" />
                    </TestimonialsCardQoutes>
                    <TestimonialsRatingStars>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    </TestimonialsRatingStars>
                    <TestimonialsCardHeading>
                        My business partner Vince and I just sold our first 5k client! We've been selling at a beta price of $2500 for the last month and a half and finally made the switch. Shout out to @Joshua Collier for his sales training help right before the call.
                    </TestimonialsCardHeading>
                </TestimonialsCardTop>
                <TestimonialsCardBottom show={showTestimonialsContent}>
                    <TestimonialsCardDivider />
                    <TestimonialsCardProfile>
                        {/* <TestimonialsCardProfileImage><Img src={adam} alt="adam" /></TestimonialsCardProfileImage> */}
                        <TestimonialsCardProfileUserData>
                            <h3>Adam and Vince</h3>
                        </TestimonialsCardProfileUserData>
                    </TestimonialsCardProfile>
                </TestimonialsCardBottom>
            </TestimonialsCard>
        </CSSTransition>
      </SwiperSlide>
      <SwiperSlide>
        <CSSTransition in={showTestimonialsContent} timeout={3000} classNames="fadeLeftToRightThree">
            <TestimonialsCard show={showTestimonialsContent}>
                <TestimonialsCardTop show={showTestimonialsContent}>
                    <TestimonialsCardQoutes>
                            <Img src={testimonialsQoutes} alt="testimonialsQoutes" />
                    </TestimonialsCardQoutes>
                    <TestimonialsRatingStars>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    </TestimonialsRatingStars>
                    <TestimonialsCardHeading>
                        Hey bro, I'm super-excited because of the business idea you gave me yesterday. Man, this is brilliant??? It's exactly what I was looking for: A SaaS product. It ticks all the boxes, tech, marketing, AND recurring revenue. Thanks buddy, you inspired me and gave me hope.May I ask you how you were able to come up with this idea in less than a second? AMAZING idea
                    </TestimonialsCardHeading>
                </TestimonialsCardTop>
                <TestimonialsCardBottom show={showTestimonialsContent}>
                    <TestimonialsCardDivider />
                    <TestimonialsCardProfile>
                        {/* <TestimonialsCardProfileImage><Img src={merlin} alt="merlin" /></TestimonialsCardProfileImage> */}
                        <TestimonialsCardProfileUserData>
                            <h3>Merlin Rabens</h3>
                        </TestimonialsCardProfileUserData>
                    </TestimonialsCardProfile>
                </TestimonialsCardBottom>
            </TestimonialsCard>
        </CSSTransition>
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialsCard show={showTestimonialsContent}>
            <TestimonialsCardTop show={showTestimonialsContent}>
                <TestimonialsCardQoutes>
                        <Img src={testimonialsQoutes} alt="testimonialsQoutes" />
                </TestimonialsCardQoutes>
                <TestimonialsRatingStars>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                </TestimonialsRatingStars>
                <TestimonialsCardHeading>
                    Hi. I just tried it on one example and I can tell you that it's great! I really love it! It really helps me ask better questions! Thank you so much!it really teaches me how to ask better questions and go deeper! muchas gracias
                </TestimonialsCardHeading>
            </TestimonialsCardTop>
            <TestimonialsCardBottom show={showTestimonialsContent}>
                <TestimonialsCardDivider />
                <TestimonialsCardProfile>
                    {/* <TestimonialsCardProfileImage><Img src={john} alt="john" /></TestimonialsCardProfileImage> */}
                    <TestimonialsCardProfileUserData>
                        <h3>Lucia Krutekova</h3>
                    </TestimonialsCardProfileUserData>
                </TestimonialsCardProfile>
            </TestimonialsCardBottom>
        </TestimonialsCard>
      </SwiperSlide>
      <SwiperSlide>
        <TestimonialsCard show={showTestimonialsContent}>
            <TestimonialsCardTop show={showTestimonialsContent}>
                <TestimonialsCardQoutes>
                        <Img src={testimonialsQoutes} alt="testimonialsQoutes" />
                </TestimonialsCardQoutes>
                <TestimonialsRatingStars>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                </TestimonialsRatingStars>
                <TestimonialsCardHeading>
                    My business partner Vince and I just sold our first 5k client! We've been selling at a beta price of $2500 for the last month and a half and finally made the switch. Shout out to @Joshua Collier for his sales training help right before the call.
                </TestimonialsCardHeading>
            </TestimonialsCardTop>
            <TestimonialsCardBottom show={showTestimonialsContent}>
                <TestimonialsCardDivider />
                <TestimonialsCardProfile>
                    {/* <TestimonialsCardProfileImage><Img src={adam} alt="adam" /></TestimonialsCardProfileImage> */}
                    <TestimonialsCardProfileUserData>
                        <h3>Adam and Vince</h3>
                    </TestimonialsCardProfileUserData>
                </TestimonialsCardProfile>
            </TestimonialsCardBottom>
        </TestimonialsCard>
      </SwiperSlide>
    </Swiper>
    </TestimonialsSwiperContainer>
  )
}

export default TestimonialsSwiper