import styled from "styled-components";

export const ProductsScrollContainer = styled.div`
height: 820px;
padding-top: 110px;

@media screen and (max-width: 1260px) {
    height: 820px;
}
`;
export const ProductsContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 20px;
height: 700px;
width: 1220px;
padding: 0 20px;

@media screen and (max-width: 1260px) {
    width: 950px;
}
@media screen and (max-width: 990px) {
    width: 730px;
}
@media screen and (max-width: 768px) {
    width: 100%;
}
`;
export const ProductsTitle = styled.h1`
font-size: 36px;
font-style: normal;
font-weight: bolder;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width: 990px) {
    font-size: 28px;
}
`;
export const ProductsTitleUnderLine = styled.div`
height: 7px;
width: 100%;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;
export const ProductsSubTitle = styled.h2`
display: ${({ show }) => (show ? 'block' : 'none')};
color: #000;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
`;
export const ProductsHeading = styled.h3`
display: ${({ show }) => (show ? 'block' : 'none')};
color: #0F172A;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
width: 871px;

@media screen and (max-width: 1260px) {
    width: 730px;
}
@media screen and (max-width: 990px) {
    width: 590px;
}
@media screen and (max-width: 768px) {
    width: 90%;
    text-align: justify;
}
`;
export const ProductsHeadingDifferent = styled.span`
background: linear-gradient(94deg, #6F2FFC 40.66%, #510295 51.94%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;
export const ProductsContent = styled.div`
width: 100%;
height: 100%;
position: relative;
`;
export const ProductsContentBackground = styled.div`
position: absolute;
right: 0;
bottom: 0;
z-index: -1;

@media screen and (max-width: 550px) {
    img{
        width: 210px;
    }
}
`;
export const ProductsContentCard = styled.div`
display: flex;
justify-content: flex-end;
width: 1120px;
height: 87%;
position: relative;

@media screen and (max-width: 1260px) {
    width: 850px;
}
@media screen and (max-width: 990px) {
    width: 630px;
}
@media screen and (max-width: 768px) {
    width: 95%;
    height: 95%;
}
`;
export const ProductsFilterBarContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100%;
width: 495px;
position: absolute;
left: 0;

@media screen and (max-width: 1260px) {
    width: 350px;
}
@media screen and (max-width: 990px) {
    width: 250px;
}
@media screen and (max-width: 768px) {
    width: 34%;
}
@media screen and (max-width: 550px) {
    width: 37%;
}
`;
export const FilterButton = styled.button`
height: 125px;
outline: none;
cursor: pointer;
background: ${({ active }) => (active ? '#7F23E6' : 'none')};
border-radius: ${({ active }) => (active ? '25px 0 0 25px' : 0)};
border: ${({ active }) => (active ? '0px solid rgba(0, 0, 0, 0.00)' : 'none')};
display: flex;
justify-content: flex-start;
align-items: center;
gap: 20px;
padding-left: 30px;
p{
display: ${({ show }) => (show ? 'block' : 'none')};
color: ${({ active }) => (active ? '#FFF' : '#000')};
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

@media screen and (max-width: 990px) {
    height: 100px;
    gap: 15px;
    p{
        font-size: 17px;
    }
}
@media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;
    height: 150px;
    gap: 10px;
    p{
        font-size: 13px;
    }
}
`;
export const FilterButtonZappyChat = styled.div`
width: 50px;
height: 50px;
border-radius: 15px;
border: 0px solid rgba(0, 0, 0, 0.00);
background: #FFF;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: ${({ show }) => (show ? 'flex' : 'none')};
justify-content: center;
align-items: center;

@media screen and (max-width: 990px) {
    width: 40px;
    height: 40px;
    padding: 8px;
}
`;
export const ProductsContentCardRightHeading = styled.h4`
color: #0F172A;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
`;
export const ProductsCardContainer = styled.div`
height: 100%;
width: 645px;
border-radius: 25px;
border: 0px solid rgba(0, 0, 0, 0.00);
background: #7F23E6;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: ${({ active }) => (active ? 'flex' : 'none')};
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 20px;
padding: 48px;

@media screen and (max-width: 1260px) {
    width: 520px;
}
@media screen and (max-width: 990px) {
    width: 400px;
}
@media screen and (max-width: 768px) {
    width: 70%;
}
@media screen and (max-width: 550px) {
    width: 70%;
    padding: 20px 10px 20px 40px;
    gap: 10px;
}
`;
export const ProductsCardZappyChat = styled.div`
width: 60px;
height: 60px;
border-radius: 15px;
border: 0px solid rgba(0, 0, 0, 0.00);
background: #FFF;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
display: ${({ show }) => (show ? 'flex' : 'none')};
justify-content: center;
align-items: center;


@media screen and (max-width: 990px) {
    height: 50px;
    width: 50px;
    padding: 8px;
}
`;
export const ProductsCardZappyChatTitle = styled.h4`
display: ${({ show }) => (show ? 'block' : 'none')};
color: #FFF;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;

@media screen and (max-width: 990px) {
    font-size: 14px;
}
`;
export const ProductsCardZappyChatHeading = styled.p`
display: ${({ show }) => (show ? 'block' : 'none')};
color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;

@media screen and (max-width: 990px) {
    font-size: 12px;
}
`;