import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import heroImage from '../../assets/images/heroImage.png'
import { Img } from '../../reuseableComponents/globalStyles'
import styled from "styled-components";
import { Link } from 'react-router-dom';
import { HeroContainer, HeroHeading, HeroHeadingDifferent, HeroLeftPart, HeroRightPart, HeroScrollContainer, HeroSubTitle, HeroSubTitleMobileView, HeroTitle } from './heroSectionStyles'

export const HeroButton = styled(Link)`
display: flex;
justify-content: center;
align-items: center;
width: 380px;
height: 50px;
border-radius: 30px;
border: 3px solid #A855F7;
background: #A855F7;
box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
font-size: 24px;
font-weight: 600;
line-height: normal;
text-decoration: none;
cursor: pointer;

@media screen and (max-width: 990px) {
    font-size: 17px;
    width: 250px;
    height: 40px;
}
@media screen and (max-width: 768px) {
    width: 200px;
    height: 30px;
    font-size: 14px;
}
`;

const HeroSection = ({activeLink}) => {
  const [showHomeContent, setShowHomeContent] = useState(false);

  useEffect(() => {
    if (activeLink === 'heroSection') {
        setShowHomeContent(true);
    }
  }, [activeLink]);
  
  return (
    <HeroScrollContainer id="heroSection">
      <HeroContainer>
          <HeroLeftPart>
            <CSSTransition in={showHomeContent} timeout={300} classNames="fadeTwo">
              <HeroTitle>“Welcome to Levrg”</HeroTitle>
            </CSSTransition>
            <CSSTransition in={showHomeContent} timeout={350} classNames="fadeThree">
              <HeroSubTitle>
                Levrg is a rapidly evolving company in the <br />
                AI client Acquisition sector, focusing <br />
                on enhancing the operational <br />
                efficiency of online businesses through cutting-edge AI <br /> 
                technology. We are committed to democratizing AI, making it <br />
                accessible and beneficial for businesses of all sizes.
              </HeroSubTitle>
            </CSSTransition>
            {/* Mobile View */}
            <CSSTransition in={showHomeContent} timeout={350} classNames="fadeThree">
              <HeroSubTitleMobileView>
                Levrg is a rapidly evolving company in the
                AI client Acquisition sector, focusing
                on enhancing the operational
                efficiency of online businesses through cutting-edge AI
                technology. We are committed to democratizing AI, making it
                accessible and beneficial for businesses of all sizes.
              </HeroSubTitleMobileView>
            </CSSTransition>
            <CSSTransition in={showHomeContent} timeout={400} classNames="fadeFour">
              <HeroHeading>
                Experience innovation that drives results with <HeroHeadingDifferent> Levrg! </HeroHeadingDifferent>
              </HeroHeading>
            </CSSTransition>
            <CSSTransition in={showHomeContent} timeout={500} classNames="fadeFive">
              <HeroButton to="comingSoon">Sign up now!</HeroButton>
            </CSSTransition>
          </HeroLeftPart>
          <HeroRightPart>
            <CSSTransition in={showHomeContent} timeout={300} classNames="fade">
              <Img src={heroImage} alt="heroImage" />
            </CSSTransition>
          </HeroRightPart>
      </HeroContainer>
    </HeroScrollContainer>
  )
}

export default HeroSection