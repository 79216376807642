import React, { useState } from 'react';
import socialButtonIcon from '../../assets/images/socialButtonIcon.png'
import chatButtonIcon from '../../assets/images/chatButtonIcon.png'
import voiceButtonIcon from '../../assets/images/voiceButtonIcon.png'
import { Img } from '../../reuseableComponents/globalStyles'
import { CSSTransition } from 'react-transition-group';
import { FilterButton, FilterButtonZappyChat, ProductsFilterBarContainer } from './productsStyles';

const ProductsFilterBar = ({setActiveProductCard, showProductsContent}) => {
  const [activeButton, setActiveButton] = useState('1');

  const handleButtonClick = (value) => {
    setActiveButton(value);
    setActiveProductCard(value);
  };

  return (
    <ProductsFilterBarContainer>
      <FilterButton active={activeButton === '1'} onClick={() => handleButtonClick('1')} show={showProductsContent}>
        <CSSTransition in={showProductsContent} timeout={500} classNames="fade">
          <FilterButtonZappyChat show={showProductsContent}>
            <Img src={socialButtonIcon} alt="socialButtonIcon" />
          </FilterButtonZappyChat>
        </CSSTransition>
        <CSSTransition in={showProductsContent} timeout={500} classNames="fade">
          <p> Levrg Social </p>
        </CSSTransition>
      </FilterButton>
      <FilterButton active={activeButton === '2'} onClick={() => handleButtonClick('2')} show={showProductsContent}>
        <CSSTransition in={showProductsContent} timeout={500} classNames="fade">
          <FilterButtonZappyChat show={showProductsContent}>
            <Img src={chatButtonIcon} alt="chatButtonIcon" />
          </FilterButtonZappyChat>
        </CSSTransition>
        <CSSTransition in={showProductsContent} timeout={500} classNames="fade">
          <p>Levrg Chat</p>
        </CSSTransition>
      </FilterButton>
      <FilterButton active={activeButton === '3'} onClick={() => handleButtonClick('3')} show={showProductsContent}>
        <CSSTransition in={showProductsContent} timeout={500} classNames="fade">
          <FilterButtonZappyChat show={showProductsContent}>
            <Img src={voiceButtonIcon} alt="voiceButtonIcon" />
          </FilterButtonZappyChat>
        </CSSTransition>
        <CSSTransition in={showProductsContent} timeout={500} classNames="fade">
          <p>Levrg Voice</p>
        </CSSTransition>
      </FilterButton>
    </ProductsFilterBarContainer>
  );
};

export default ProductsFilterBar;
