import React, { useEffect, useState } from 'react'
import { TestimonialsCardBottom, TestimonialsCardDivider, TestimonialsCardProfile, TestimonialsCardProfileUserData, TestimonialsCardQoutes, TestimonialsCardTop, TestimonialsContainer, TestimonialsHeading, TestimonialsHeadingDifferent, TestimonialsRatingStar, TestimonialsRatingStars, TestimonialsScrollContainer, TestimonialsSubTitle, TestimonialsTitle, TestimonialsTitleUnderLine, TestimonialsVideoCard, TestimonialsVideoCardHeading, TestimonialsVideoContainer } from './testimonialsStyle'
import TestimonialsSwiper from './testimonialsSwiper'
import { Img } from '../../reuseableComponents/globalStyles'
import TestimonialsVideo from './testimonialsVideo'
import testimonialsQoutes from '../../assets/images/testimonialsQoutes.png'
// import jane from '../../assets/images/jane.png'
import filledStar from '../../assets/images/filledStar.png'
import { CSSTransition } from 'react-transition-group';

const Testimonials = ({activeLink}) => {
  const [showTestimonialsContent, setShowTestimonialsContent] = useState(false);
  
  useEffect(() => {
      if (activeLink === 'testimonials') {
          setShowTestimonialsContent(true);
      }
    }, [activeLink]);

  return (
    <TestimonialsScrollContainer id="testimonials">
      <TestimonialsContainer>
            <TestimonialsTitle>Testimonials<TestimonialsTitleUnderLine /></TestimonialsTitle>
          <CSSTransition in={showTestimonialsContent} timeout={300} classNames="fadeThree">
            <TestimonialsSubTitle show={showTestimonialsContent}>Client Success Stories</TestimonialsSubTitle>
          </CSSTransition>
          <CSSTransition in={showTestimonialsContent} timeout={400} classNames="fadeFour">
            <TestimonialsHeading show={showTestimonialsContent}>
             Hear from our <TestimonialsHeadingDifferent> satisfied clients </TestimonialsHeadingDifferent> who have experienced the transformative power of Levrg's AI solutions. Their stories highlight increased efficiency, improved lead generation, and enhanced customer engagement. Let Levrg be the catalyst for your business's growth and innovation.
            </TestimonialsHeading>
          </CSSTransition>
          <TestimonialsVideoContainer>
              <TestimonialsVideo />
            <TestimonialsVideoCard>
              <TestimonialsCardTop show={showTestimonialsContent}>
                <CSSTransition in={showTestimonialsContent} timeout={400} classNames="fadeRightToLeft">
                    <TestimonialsCardQoutes>
                      <Img src={testimonialsQoutes} alt="testimonialsQoutes" />
                    </TestimonialsCardQoutes>
                  </CSSTransition>
                  <CSSTransition in={showTestimonialsContent} timeout={1000} classNames="fadeLeftToRightOne">
                    <TestimonialsRatingStars>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                        <TestimonialsRatingStar><Img src={filledStar} alt="filledStar" /></TestimonialsRatingStar>
                    </TestimonialsRatingStars>
                  </CSSTransition>
                  <CSSTransition in={showTestimonialsContent} timeout={500} classNames="fadeFive">
                    <TestimonialsVideoCardHeading>
                        I couldn't believe my eyes
                    </TestimonialsVideoCardHeading>
                  </CSSTransition>
                </TestimonialsCardTop>
                <TestimonialsCardBottom show={showTestimonialsContent}>
                  <TestimonialsCardDivider />
                  <TestimonialsCardProfile>
                    {/* <CSSTransition in={showTestimonialsContent} timeout={1000} classNames="fade">
                      {/* <TestimonialsCardProfileImage>
                        <Img src={jane} alt="jane" />
                      </TestimonialsCardProfileImage> */}
                    {/* </CSSTransition> */}
                    <CSSTransition in={showTestimonialsContent} timeout={500} classNames="fadeFive">
                      <TestimonialsCardProfileUserData>
                          <h3>Kerri Anne Kedziora</h3>
                      </TestimonialsCardProfileUserData>
                    </CSSTransition>
                  </TestimonialsCardProfile>
                </TestimonialsCardBottom>
            </TestimonialsVideoCard>
          </TestimonialsVideoContainer>
          <TestimonialsSwiper showTestimonialsContent={showTestimonialsContent}/>
      </TestimonialsContainer>
    </TestimonialsScrollContainer>
  )
}

export default Testimonials