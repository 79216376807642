import styled from "styled-components";
import { Link } from 'react-scroll';

export const HeaderContainer = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
background: #FFF;
position: sticky;
top: 0;
z-index: 10;

@media screen and (max-width: 768px) {
    position: fixed;
    height: 60px;
}
`;
export const Logo = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: #0F172A;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding: 10px 0 0 20px;
cursor: pointer;

@media screen and (max-width: 990px) {
    height: 80px;
    font-size: 16px;
    img{
        height: 70px;
    }
}
@media screen and (max-width: 768px) {
    height: 60px;
    width: 150px;
    padding: 5px 0 0 10px;
    font-size: 13px;
    gap: 5px;
    img{
        height: 50px;
    }
}
`;
export const NavbarContainer = styled.div`
width: 70%;
height: 100px;
border-radius: 0 0 0 50px;
background: linear-gradient(267deg, #34285F 57.49%, #0F172A 100.39%);
display: flex;
align-items: center;
position: relative;

@media screen and (max-width: 990px) {
    height: 80px;
}
@media screen and (max-width: 768px) {
  height: ${({ isOpen }) => (isOpen ? "300px" : "60px")};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
`;
export const Menu = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
padding: 0 30px;

@media screen and (max-width: 990px) {
    padding: 0 20px;
}
@media (max-width: 768px) {
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  padding: 20px;
}
`;
export const MenuLink = styled(Link)`
color: #F5F5F5;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration: none;
cursor: pointer;

&.active{
    font-size: 20px;
    color: #A855F7;
    font-weight: 900;
}

@media screen and (max-width: 990px) {
    font-size: 15px;

    &.active{
    font-size: 16px;
}
}
`;

export const Hamburger = styled.div`
display: none;
cursor: pointer;


@media (max-width: 768px) {
  display: block;
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 1;
  color: #fff;
  font-size: 25px;
}
`;