import styled from "styled-components";

export const PricingScrollContainer = styled.div`
height: 830px;
padding-top: 110px;

@media screen and (max-width: 1260px) {
    height: 730px;
}
@media screen and (max-width: 990px) {
    height: 640px;
}
@media screen and (max-width: 768px) {
    height: 1370px;
}
`;
export const PricingContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 15px;
padding: 0 20px;
width: 1210px;

@media screen and (max-width: 1260px) {
    width: 950px;
}
@media screen and (max-width: 990px) {
    width: 730px;
}
@media screen and (max-width: 768px) {
    width: 100%;
}
`;
export const PricingTitle = styled.h1`
font-size: 36px;
font-style: normal;
font-weight: bolder;
line-height: normal;
background: linear-gradient(257deg, #510295 40.75%, #6F2FFC 73.03%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

@media screen and (max-width: 990px) {
    font-size: 28px;
}
`;
export const PricingTitleUnderLine = styled.div`
height: 7px;
width: 100%;
background: linear-gradient(89deg, #6F2FFC 37.48%, #510295 75.77%);
`;
export const PricingSubtitle = styled.h3`
display: ${({ show }) => (show ? 'block' : 'none')};
color: #0F172A;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;

@media screen and (max-width: 768px) {
    font-size: 16px;
}
`;
export const PricingHeading = styled.p`
display: ${({ show }) => (show ? 'block' : 'none')};
width: 880px;
color: #000;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;

@media screen and (max-width: 1260px) {
    width: 780px;
}
@media screen and (max-width: 990px) {
    width: 620px;
}
@media screen and (max-width: 768px) {
    width: 90%;
    text-align: justify;
}
`;
export const PricingHeadingDifferent = styled.span`
background: linear-gradient(89deg, #6F2FFC 0.77%, #510295 4.05%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
`;
export const PricingMethodsContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 20px;
width: 100%;

@media screen and (max-width: 768px) {
    flex-direction: column;
}
`;
export const PricingMethodsCard = styled.button`
outline: none;
cursor: pointer;
background: ${({ active }) => (active ? '#7F23E6' : 'none')};
color: ${({ active }) => (active ? '#FFF' : '#000')};
border-radius: 25px;
border: 1px solid #7F23E6;
display: ${({ show }) => (show ? 'flex' : 'none')};
flex-direction: column;
justify-content: space-between;
align-items: center;
padding: 60px 20px;
height: 500px;
width: 372px;
margin-top: ${({ active }) => (active ? 0 : '50px')};

@media screen and (max-width: 1260px) {
    width: 300px;
    height: 400px;
}
@media screen and (max-width: 990px) {
    width: 220px;
    height: 300px;
    padding: 30px 20px;
}
@media screen and (max-width: 768px) {
    width: 300px;
    height: 320px;
    margin-top: 10px;
}
`;
export const PricingMethodsCardTitle = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 30px;

p{
    color: ${({ active }) => (active ? '#FFF' : '#000')};
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
h2{
    color: ${({ active }) => (active ? '#FFF' : '#0F172A')};
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}
span{
    color: ${({ active }) => (active ? '#FFF' : '#0F172A')};
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}

@media screen and (max-width: 1260px) {
    p{
        font-size: 19px;
    }
    h2{
        font-size: 32px;
    }
    span{
        font-size: 26px;
    }
}
@media screen and (max-width: 990px) {
    p{
        font-size: 14px;
    }
    h2{
        font-size: 26px;
    }
    span{
        font-size: 20px;
    }
}
`;
export const PricingMethodsContentContainer = styled.div`
display: flex;
align-items: flex-end;
gap: 20px;
`;
export const PricingMethodsContent = styled.ul`
display: flex;
flex-direction: column;
gap: 20px;
padding-bottom: 17px;
`;
export const PricingMethodsContentHeading = styled.li`
color: ${({ active }) => (active ? '#FFF' : '#000')};
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
`;
export const PricingMethodsContentHeadingButton = styled.button`
outline: none;
cursor: pointer;
border-radius: 20px;
border: ${({ active }) => (active ? '1px solid #FFF' : '1px solid #7F23E6')};
background: rgba(217, 217, 217, 0.00);
width: 104px;
height: 50px;
color: ${({ active }) => (active ? '#FFF' : '#000')};
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;
export const PricingMethodsButton = styled.button`
outline: none;
cursor: pointer;
width: 226px;
height: 50px;
border-radius: 30px;
border: ${({ active }) => (active ? '1px solid #FFF' : '1px solid #7F23E6')};
background: ${({ active }) => (active ? '#FFF' : '#A855F7')};
color: ${({ active }) => (active ? '#A855F7' : '#FFF')};
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;

@media screen and (max-width: 1260px) {
    width: 180px;
    height: 40px;
    font-size: 14px;
}
`;