import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { PricingMethodsButton, PricingMethodsCard, PricingMethodsCardTitle, PricingMethodsContainer, PricingMethodsContentContainer } from './pricingStyles';

const PricingMethods = ({showPricingContent}) => {
  const [activeButton, setActiveButton] = useState('2');

  const handleButtonClick = (value) => {
    setActiveButton(value);
  };

  return (
    <PricingMethodsContainer>
      <CSSTransition in={showPricingContent} timeout={1500} classNames="fadeLeftToRightOne">
        <PricingMethodsCard active={activeButton === '1'} onClick={() => handleButtonClick('1')} show={showPricingContent}>
          <PricingMethodsCardTitle active={activeButton === '1'}>
              <p>Levrg Social / AI Campaigns</p>
              <h2>$497/<span>month</span></h2>
          </PricingMethodsCardTitle> 
          <PricingMethodsContentContainer>
          </PricingMethodsContentContainer>
          <PricingMethodsButton active={activeButton === '1'}>Get Started!</PricingMethodsButton>
        </PricingMethodsCard>
      </CSSTransition>
      <CSSTransition in={showPricingContent} timeout={2000} classNames="fadeLeftToRightTwo">
        <PricingMethodsCard active={activeButton === '2'} onClick={() => handleButtonClick('2')} show={showPricingContent}>
          <PricingMethodsCardTitle active={activeButton === '2'}>
              <p>Levrg Chat / AI Setter</p>
              <h2>$997/<span>month</span></h2>
          </PricingMethodsCardTitle> 
          <PricingMethodsContentContainer>
          </PricingMethodsContentContainer>
          <PricingMethodsButton active={activeButton === '2'}>Get Started!</PricingMethodsButton>
        </PricingMethodsCard>
      </CSSTransition>
      <CSSTransition in={showPricingContent} timeout={3000} classNames="fadeLeftToRightThree">
        <PricingMethodsCard active={activeButton === '3'} onClick={() => handleButtonClick('3')} show={showPricingContent}>
          <PricingMethodsCardTitle active={activeButton === '3'}>
              <p>AI Voice / AI Closer</p>
              <h2>Let’s Talk!</h2>
          </PricingMethodsCardTitle> 
          <PricingMethodsContentContainer>
          </PricingMethodsContentContainer>
          <PricingMethodsButton active={activeButton === '3'}>Get Started!</PricingMethodsButton>
        </PricingMethodsCard>
      </CSSTransition>
    </PricingMethodsContainer>
  );
};

export default PricingMethods;
