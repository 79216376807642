import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import aboutUsMens from '../../assets/images/aboutUsMens.png'
import { Img } from '../../reuseableComponents/globalStyles'
import { AboutUsContainer, AboutUsContent, AboutUsContentBackground, AboutUsContentCard, AboutUsContentCardRight, AboutUsContentCardRightProperties, AboutUsContentCardRightProperty, AboutUsContentCardRightPropertyHeading, AboutUsContentCardRightPropertyHeadingDifferent, AboutUsContentCardRightPropertyIcon, AboutUsContentCardRightPropertyIconContainer, AboutUsContentCardRightTitle, AboutUsScrollContainer, AboutUsTitle, AboutUsTitleUnderLine } from './aboutUsStyles'
import Team from '../team/team'
const AboutUs = ({activeLink}) => {
    const [showAboutContent, setShowAboutContent] = useState(false);
    
    useEffect(() => {
        if (activeLink === 'aboutUs') {
            setShowAboutContent(true);
        }
      }, [activeLink]);

  return (
    <AboutUsScrollContainer id="aboutUs">
        <AboutUsContainer>
                <AboutUsTitle>About Us<AboutUsTitleUnderLine /></AboutUsTitle>
            <AboutUsContent>
                <AboutUsContentBackground></AboutUsContentBackground>
                <AboutUsContentCard show={showAboutContent}>
                    <CSSTransition in={showAboutContent} timeout={500} classNames="fade">
                        <Img src={aboutUsMens} alt="aboutUsMens" />
                    </CSSTransition>
                    <AboutUsContentCardRight show={showAboutContent}>
                        <CSSTransition in={showAboutContent} timeout={300} classNames="fadeThree">
                            <AboutUsContentCardRightTitle>“Our Mission”</AboutUsContentCardRightTitle>
                        </CSSTransition>
                        <AboutUsContentCardRightProperties>
                            <CSSTransition in={showAboutContent} timeout={350} classNames="fadeFour">
                                <AboutUsContentCardRightProperty>
                                    <AboutUsContentCardRightPropertyIconContainer><AboutUsContentCardRightPropertyIcon /></AboutUsContentCardRightPropertyIconContainer>
                                    <AboutUsContentCardRightPropertyHeading>
                                        <AboutUsContentCardRightPropertyHeadingDifferent>At Levrg</AboutUsContentCardRightPropertyHeadingDifferent>, we're on a mission to redefine the AI Client Acquisition landscape.
                                    </AboutUsContentCardRightPropertyHeading>
                                </AboutUsContentCardRightProperty>
                            </CSSTransition>
                            <CSSTransition in={showAboutContent} timeout={400} classNames="fadeFour">
                                <AboutUsContentCardRightProperty>
                                    <AboutUsContentCardRightPropertyIconContainer><AboutUsContentCardRightPropertyIcon /></AboutUsContentCardRightPropertyIconContainer>
                                    <AboutUsContentCardRightPropertyHeading>
                                        Our mantra, <AboutUsContentCardRightPropertyHeadingDifferent>"Work Less, Profit More,"</AboutUsContentCardRightPropertyHeadingDifferent> drives our approach to simplify and enhance business interactions with AI technology.
                                    </AboutUsContentCardRightPropertyHeading>
                                </AboutUsContentCardRightProperty>
                            </CSSTransition>
                            <CSSTransition in={showAboutContent} timeout={500} classNames="fadeFive">
                                <AboutUsContentCardRightProperty>
                                    <AboutUsContentCardRightPropertyIconContainer><AboutUsContentCardRightPropertyIcon /></AboutUsContentCardRightPropertyIconContainer>
                                    <AboutUsContentCardRightPropertyHeading>
                                        We're paving the way towards user-friendly AI solutions, making us a leader in lead generation and conversion.
                                    </AboutUsContentCardRightPropertyHeading>
                                </AboutUsContentCardRightProperty>
                            </CSSTransition>
                        </AboutUsContentCardRightProperties>
                    </AboutUsContentCardRight>
                </AboutUsContentCard>
            </AboutUsContent>
            <Team />
        </AboutUsContainer>
    </AboutUsScrollContainer>
  )
}

export default AboutUs